<template>
  <!-- v-click-outside="onClickOutside"  -->
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-5'"
        :dark="$vuetify.theme.dark"
        class=""
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            [{{ patientData && patientData.name }}]{{ $t('patient.title')}}
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <ControlUI
      :patientData="patientData"
      ref="refControlUI"
      @openActivity="openActivity"
      @openUserInfo="openUserInfoEdit"
      @openSurvey="addSurvey"
      @fedRequest="fedRequest"
      @openCareNote="openCareNoteForPage"
      @openCareNodeForTimer="openCareNodeForTimer"
    />

    <v-tabs
      v-model="tabIdx"
      align-tabs="center"
      bg-color="deep-purple-accent-4"
      stacked
      @change="tabClick"
    >
      <v-tab> {{ $t('patient.detail.nav.careOverView.title') }} </v-tab>
      <v-tab> {{ $t('patient.detail.nav.medicalInformation.title') }} </v-tab>
      <!-- <v-tab> {{ $t('patient.detail.nav.preventiveCare') }} </v-tab> -->
      <!-- <v-tab> {{ $t('patient.detail.nav.patientAssessment') }} </v-tab> -->
      <!-- <v-tab> {{ $t('patient.detail.nav.actionCarePlan') }} </v-tab> -->
      <!-- <v-tab> {{ $t('patient.detail.nav.carePlanHistory') }} </v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tabIdx">
      <CareOverview ref="refCareOverview" :patientData="patientData" />
      <MedicalInformation
        ref="refMedicalInformation"
        :patientData="patientData"
      />
      <!-- <PreventiveCare ref="refPreventiveCare" :patientData="patientData" /> -->
      <!-- <Assessment ref="refAssessment" :patientData="patientData" /> -->
      <!-- <ActionCarePlan ref="refActionCarePlan" :patientData="patientData" /> -->
      <!-- <CarePlanHistory :patientData="patientData" /> -->
    </v-tabs-items>

    <!-- SMS/email 발송 -->
    <DialogInputFid ref="refDialogInputFid" />

    <!-- 케어노트(timer) -->
    <DialogCareNoteForTimer
      ref="refCareNoteForTimer"
      @saveComplete="careNoteSaveComplete"
      @clickCancel="careNoteClickCancel"
      @withoutSaveClose="withoutSaveClose"
    />

    <!-- 환자별 설문 -->
    <v-navigation-drawer
      v-model="SurveyPageDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="70%"
    >
      <SurveyPage-drawer
        ref="refSurvey"
        @closeDrawer="SurveyPageDrawer = false"
      >
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="SurveyPageDrawer = !SurveyPageDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </SurveyPage-drawer>
    </v-navigation-drawer>

    <!-- 케어노트(page) CareNoteForPageDrawer -->
    <v-navigation-drawer
      v-model="CareNoteForPageDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="70%"
    >
      <CareNoteForPage-drawer
        ref="refCareNoteForPage"
        @closeDrawer="CareNoteForPageDrawer = false"
      >
        <template v-slot:userDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="CareNoteForPageDrawer = !CareNoteForPageDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </CareNoteForPage-drawer>
    </v-navigation-drawer>

    <!-- 편집창 -->
    <DialogPatientAdd
      ref="refPatientAdd"
      @complete="userEditorCompleteSaveData"
    />
  </vue-perfect-scrollbar>
</template>
<script>
import { mapActions } from "vuex";
import ControlUI from "./controlUI/ControlUI.vue";
import CareOverview from "./careOverview/CareOverview.vue";
import MedicalInformation from "./medicalInformation/MedicalInformation.vue";
import PreventiveCare from "./preventiveCare/PreventiveCare.vue";
import Assessment from "./assessment/Assessment.vue";
import ActionCarePlan from "./actionCarePlan/ActionCarePlan.vue";
import CarePlanHistory from "./carePlanHistory/CarePlanHistory.vue";
import DialogPatientAdd from "@/components/commonV2/Dialog/DialogPatientAdd.vue";
import DialogCareNoteForTimer from "@/components/commonV2/Dialog/DialogCareNoteForTimer.vue";
import DialogInputFid from "@/components/commonV2/Dialog/DialogInputFid.vue";

export default {
  components: {
    CareNoteForPageDrawer: () => import("./careNote/CareNoteForPage.vue"),
    SurveyPageDrawer: () => import("./survey/SurveyPage.vue"),
    ControlUI,
    CareOverview,
    MedicalInformation,
    PreventiveCare,
    Assessment,
    ActionCarePlan,
    CarePlanHistory,
    DialogPatientAdd,
    DialogCareNoteForTimer,
    DialogInputFid,
  },
  data() {
    return {
      tabIdx: 0,
      patientData: null,
      CareNoteForPageDrawer: false,
      SurveyPageDrawer: false,
      QuestionnaireDrawer: false,
    };
  },
  watch: {},
  methods: {
    _updateData(_patientData) {
      console.log("tabs _patientData", _patientData);
      this.patientData = _patientData;
      this.tabClick(this.tabIdx);

      // care time 시작 / 초기화
      setTimeout(() => {
        this.setTimer("play");
      }, 300);
    },
    tabClick(e) {
      console.log("e", e);
      this.tabIdx = e;
      setTimeout(() => {
        if (e == 0) this.$refs.refCareOverview._infoUpdate();
        if (e == 1) this.$refs.refMedicalInformation._infoUpdate();
        if (e == 2) this.$refs.refPreventiveCare._infoUpdate();
        if (e == 3) this.$refs.refAssessment._infoUpdate();
        if (e == 4) this.$refs.refActionCarePlan._infoUpdate(this.patientData);
      }, 100);
    },
    // drawer가 닫힐때 처리
    onClose() {
      // 타이머 stop
      this.setTimer("stop");
      this.openCareNodeForTimer();
    },
    // 외부 클릭
    onClickOutside(e) {
      // console.log('onClickOutside',e);
      // e.stopPropagation();
    },
    openCareNoteForPage() {
      console.log("openCareNoteForPage");
      this.CareNoteForPageDrawer = !this.CareNoteForPageDrawer;
      setTimeout(() => {
        this.$refs.refCareNoteForPage._showDrawer(this.patientData);
      }, 300);
    },
    openActivity() {
      this.$refs.refActivity._showDrawer(this.patientData);
    },
    addSurvey() {
      console.log("openSurveyPageDrawer");
      this.SurveyPageDrawer = !this.SurveyPageDrawer;
      setTimeout(() => {
        this.$refs.refSurvey._showDrawer(this.patientData);
      }, 300);
    },
    fedRequest(option) {
      setTimeout(
        () => this.$refs.refDialogInputFid.open(this.patientData, option),
        300
      );
    },
    openCareNodeForTimer() {
      // this.$refs.refControlUI._pauseTimer();
      this.$refs.refCareNoteForTimer._showModal(this.patientData);
    },
    careNoteClickCancel() {
      // 타이머 stop --> 완전히 종료필요
      // console.log('tab page visible', this.visible);
      if (!this.SurveyPageDrawer) {
        this.setTimer("resume");
      } else {
        this.setTimer("stop");
      }
    },
    careNoteSaveComplete() {
      this.$emit("careNoteSaveComplete");
      this.setTimer("stop");
    },
    withoutSaveClose() {
      this.$emit("withoutSaveClose");
      this.setTimer("stop");
    },
    openUserInfoEdit() {
      this.$refs.refPatientAdd.Open(
        this.patientData,
        this.patientData.hospital_id
      );
    },
    userEditorCompleteSaveData() {
      this.$emit("completeSaveData");
    },
    ...mapActions(["setTimer"]),
  },
};
</script>
<style lang="scss">
header.grey.lighten-5.theme--light.v-app-bar.v-app-bar--fixed.v-sheet.v-toolbar.v-toolbar--flat {
  top: 0;
}
.mainStyle {
  padding: 30px 0px 0px 10px !important;
}
</style>